<template>
  <en-dialog
    :model-value="modelValue"
    width="80%"
    :title="code === 'PUC' ? '采购单列表' : code === 'PUCR' ? '采退单列表' : code === 'OTH' ? '其他进货单列表' : '移库入库单列表'"
    @close="$emit('update:model-value', false)"
  >
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>
    <form-query :model="form.data" :method="table.get">
      <en-form-item label="单据搜索" v-if="code === 'PUC'||code === 'PUCR'||code === 'OTH'">
        <en-input v-model="form.data.quickSearch" placeholder="请输入采购单号/供应商/仓库/制单人"></en-input>
      </en-form-item>
   
      <en-form-item label="编码">
        <en-input v-model="form.data.goodsSerialNo"></en-input>
      </en-form-item>
      <en-form-item label="配件名称" v-if="code === 'PUC' && code === 'OTH'">
        <en-input v-model="form.data.goodsName"></en-input>
      </en-form-item>
      <en-form-item label="入库状态" v-if="code === 'PUC' && code === 'OTH'">
        <select-maintain
          v-model="form.data.stockInProgressCode"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STKPRG']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="适用车型">
        <en-input v-model="form.data.goodsVehicleSpec"></en-input>
      </en-form-item>
      <en-form-item label="OE号">
        <en-input v-model="form.data.goodsOem"></en-input>
      </en-form-item>
      <en-form-item label="计划到货" v-if="code === 'OTH'">
        <en-date-picker v-model="form.data.plannedArrivalDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="退货仓库" v-if="code === 'PUCR'">
        <select-maintain
          v-model="form.data.warehouseId"
          :ajax="{
            action: 'GET /enocloud/common/warehouse',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: 'id' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="退库状态">
        <select-maintain
          v-model="form.data.stockOutProgressCode"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['STKPRG']) }"
          :props="{ label: 'message', value: 'code' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </form-query>
    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['PCSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      :code="manifest.code"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
      show-summary
      :summary-method="table.summaryMethod"
    >
      <template #FREIGHT_DOCUMENT="{ row }: { row: EnocloudInventoryDefinitions['PurchaseQueryDto'] }">
        <en-button v-if="row.logisiticsImageUrlString" type="primary" link @click="table.logisiticsImageUrls.click(row)">查看</en-button>
      </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'
import { calculator } from '@enocloud/utils'
import { EnMessageBox, EnImage } from '@enocloud/components'
import type { SummaryMethod, TableColumnCtx, TableInstance, ColumnStyle, CellStyle } from 'element-plus'
export default factory({
  props: {
    modelValue: Boolean,
    code: {
      type: String as PropType<'PUC' | 'PUCR' | 'OTH' | 'TRA'>,
      default: 'PUC'
    }
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        export: {
          async click() {
            switch (this.code) {
              case 'PUC':
                this.ajax('GET /enocloud/purchase/export', this.table.$ajaxParams)
                break
              case 'PUCR':
                this.ajax('GET /enocloud/purchase/export', this.table.$ajaxParams)
                break
              case 'OTH':
                break
              case 'TRA':
                break
            }
            this.store.openDownload()
          }
        }
      },
      tabs: {
        active: ''
      },
      manifest: {
        computed: {
          code() {
            switch (this.code) {
              case 'PUC':
                return 'PUCIFD'
              case 'PUCR':
                return 'PUCRFD'
              case 'OTH':
                return 'PUCOFD'
              case 'TRA':
                return 'STFIFD'
            }
          }
        }
      },
      form: {
        data: {
          supplier: {},
          supplierId: '',
          warehouse: {},
          warehouseId: '',
          stockInProgressCode: '',
          branchIds: [],
          quickSearch: '',
          goodsVehicleSpec: '',
          goodsSerialNo: '',
          goodsName: '',
          goodsOem: '',
          plannedArrivalDate: '',

          stockOutProgressCode: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/purchase/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data, statusCode: this.tabs.active, typeCode: this.code }
            }
          }
        },
        summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
          columns: TableColumnCtx<T>[]
          data: T[]
        }) {
          return ctx.columns
            .map((item) => item.property)
            .map((key, index) => {
              if (['count', 'stockedCount', 'amount', 'payableAmount', 'stockedAmount'].includes(key)) {
                return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
              } else return !index ? '合计' : '-'
            })
        },
        config: {
          OPERATION: { visible: false },
          STOCK_IN_SERIAL_NOS: { header: { filter: { type: 'text', field: 'stockInSerialNo' } } },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          INVOICE_TYPE: { header: { filter: { type: 'text', field: 'invoiceTypeName' } } },
          LOGISTICS_COMPANY: { header: { filter: { type: 'text', field: 'logisticsCompany' } } },
          WAREHOUSE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'warehouseId',
                props: {
                  ajax: { action: 'GET /enocloud/common/warehouse', params: (value) => ({ name: value }) },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'preparedById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SUPPLIER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'supplierId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, type: 'B', rsexp: 'id,name' })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgressCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLMTPRG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['startDate', 'endDate'], props: { type: 'daterange' } } } },
          BUSINESS_DATE: { header: { filter: { type: 'date', field: ['businessStartDate', 'businessEndDate'], props: { type: 'daterange' } } } },
          COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          OTHER_TYPE: {
            header: {
              filter: {
                type: 'text',
                field: 'otherType'
              }
            }
          },
          MODIFIED_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'auditById',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          }
        },
        children: {
          logisiticsImageUrls: {
            click(row: EnocloudInventoryDefinitions['PurchaseQueryDto']) {
              EnMessageBox({
                title: '物流单照片',
                message: h(
                  'div',
                  { class: 'flex gap-4 flex-wrap' },
                  row.logisiticsImageUrls.map((url) => {
                    return h(EnImage, { src: url, previewSrcList: row.logisiticsImageUrls, style: 'width: 100px; height: 100px' })
                  })
                )
              })
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
